import { Controller } from "stimulus"
import 'whatwg-fetch'
import h from 'hyperscript'
import strftime from 'strftime'

export default class extends Controller {
  static targets = [ 'list' ]
  connect() {
    this.fetch()
  }


  fetch() {
    window.fetch('/beer_orders.json')
          .then(function(response) {
            return response.json()
          }).then((orders) => {
            this.listTarget.innerHTML = ''
            
            var riggerCount = 0

            for(const order of orders) {
              riggerCount = order.line_items.reduce(
                (accumulator, item) => {
                  return accumulator + parseInt(item.quantity)
                }, 0)

              
              let address = "Brewery Pick Up"
              let zone    = "PICK-UP" 
              
              let sa = order['shipping_address'] 
              
              if(sa !== undefined) {
                address = sa.address1
                zone    = sa.zone
              }

              const row = h('tr',
                h('td', 
                  h('a', `#${order.order_number}`, {
                    href: `https://brewaucracy.myshopify.com/admin/orders/${order.id}`,
                    target: '_blank'
                  })
                ),
                h('td', strftime("%a, %d %b - %H:%M %P", new Date(order.created_at))),
                h('td', order.name),
                h('td', order.email),
                h('td', address),
                h('td', zone),
                h('td', riggerCount + "x"),
                h('td', h('a', "REPRINT", { 
                  "href": `/beer_orders/${order.id}`,
                  "data-method": "PUT",
                  "data-printed": order.docket_printed
                })),
                h('td', h('a', "REPRINT", {
                  "href": `/beer_orders/${order.id}`,
                  "data-method": "PUT",
                  "data-printed": order.labels_printed
                }))
              )

              this.listTarget.appendChild(row)
            }
          })
  }
}